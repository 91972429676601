import { GrowthBook } from "@growthbook/growthbook";
import Cookies from "js-cookie";
import UAParser from "ua-parser-js";

window.dataLayer = window.dataLayer || [];

function getGrowthbookDefaultAttributes() {
  const deviceInfo = new UAParser(window.navigator.userAgent).getResult();

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  return {
    url: location.href,
    path: location.pathname,
    host: location.host,
    query: location.search,
    pageTitle: window.pageProperties?.title || document.title,
    referrer: document.referrer,
    utmMedium: params.utm_medium || Cookies.get("utm_medium"),
    utmCampaign: params.utm_campaign || Cookies.get("utm_campaign"),
    utmSource: params.utm_source || Cookies.get("utm_source"),
    utmContent: params.utm_content || Cookies.get("utm_content"),
    utmTerm: params.utm_term || Cookies.get("utm_term"),
    loginClickCount: Number(Cookies.get("login_click_count") || 0),
    signupCount: Number(Cookies.get("signup_count") || 0),
    pageType: window.pageProperties?.page_type,
    relativeUrl: window.pageProperties?.relative_url,
    locale: window.pageProperties?.locale,
    deviceType: deviceInfo.device.type || "desktop",
    browser: deviceInfo.browser.name || "unknown",
    os: deviceInfo.os.name || "unknown",
  };
}

async function init() {
  if (Cookies.get("functional_cookie_consent") !== "1") return;
  const id = Cookies.get("_ga");

  const growthbook = new GrowthBook({
    apiHost: "https://cdn.growthbook.io",
    clientKey: "sdk-DKRVtx4XyiFcRPr",
    enableDevMode: true,
    trackingCallback: (experiment, result) => {
      window.dataLayer.push({
        event: "gb_experiment_viewed",
        experimentId: experiment.key,
        variationId: result.key,
      });
    },
    attributes: {
      id,
      ...getGrowthbookDefaultAttributes(),
    },
  });

  // Wait for features to be available
  await growthbook.init({ streaming: true });

  if (!id) checkGtagAndRunCallback(growthbook);
  window.gb = growthbook;
}

let checkCookieCount = 0;
function checkGtagAndRunCallback(growthbook) {
  checkCookieCount++;
  const id = Cookies.get("_ga");
  if (id) {
    growthbook.setAttributes({
      id,
      ...getGrowthbookDefaultAttributes(),
    });
  } else if (checkCookieCount < 20) {
    setTimeout(() => checkGtagAndRunCallback(growthbook), 100);
  }
}

init();
